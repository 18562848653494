import React, {useState, useEffect} from 'react';
import {colors} from "../../const/colors";
import styled from "styled-components"
import {motion, useAnimation} from "framer-motion";
import {media_queries} from "../../const/media_queries";


export const AccordionList = styled(({ className, lang, accordionItems, handleClick, reactIcon, __html }) => {

    return <div className={className}>
        { accordionItems && accordionItems.map((item) => {
            return <AccordionItem lang={lang} __html={__html} reactIcon={reactIcon} size={item.size} key={item.id} item={item} handleClick={handleClick} />
        })}
    </div>
})`

`

export const AccordionItem = styled(({className, lang, item, handleClick, reactIcon, __html}) => {

    return (
        <div className={className}>
            <div className="header" onClick={() => handleClick(item)}>
                { reactIcon && <div style={{width: item.icon.size ? item.icon.size : null}}>{item.icon.name}</div>}
                { !reactIcon && <div style={{width: item.icon.size ? item.icon.size : null}}><img src={item.icon.name} alt=""/></div>}
                <span>{item.title[lang]}</span>
            </div>
            <div className={`content ${ item.open ? 'open' : null }`}>
                {__html ? <p dangerouslySetInnerHTML={{
                    __html: item.content[lang]
                }} /> : <p>{item.content[lang]}</p>}
            </div>
        </div>
    );
})`
  margin-bottom: 20px;
.header {
  display:flex;
  align-items: center;
  div:first-child {
    width:27px;
    margin-right:10px;
  }
  &:hover {
    cursor:pointer;
  }
  svg {
    margin-right:10px;
    color:${colors.grayColor};
  }
  span {
    font-weight: bold;
    color: ${colors.grayColor}
  }
}
  .content {
    display:flex;
    align-items: center;
    height:0;
    padding-left:20px;
    overflow: hidden;
    transition: all .4s ease;
    p {
      margin:0;
    }
    //padding:30px 0;
    &.open {
      //height:140px;
      height:auto;
      //@media screen and (min-width: 700px){
      //  height:180px;
      //}
      //@media screen and (min-width: 1014px) {
      //  height:140px;
      //}
      //margin-top:10px;
      margin:30px 0;
    }
  }
`


const ImageAccordion = styled(({className, lang, accordionItems, withoutImage, reactIcon, __html}) => {
    const controls = useAnimation();

    const variants = {
        visible: {
            opacity:1,
            y: 0,
            transition: {
                duration:0.7
            }
        },
        hidden: {
            opacity: 0,
            y:"-50px",
            transition: {
                duration: 0
            }
        }
    }
    const [itemImage, setItemImage] = useState(null)
    const [accItems, setAccItems] = useState(accordionItems)
    useEffect(() => {
        setAccItems(accordionItems)
    },[accordionItems])
    const handleClick = (item) => {
        if(!item.open) {
            setItemImage(item.image);
            controls.start("hidden").then(() => {
                controls.start("visible")
            })
        }
        setAccItems(prevState => {
            const newState = prevState.map((aitem) => {
                if(aitem.id === item.id){
                    aitem.open = !aitem.open
                }else {
                    aitem.open = false
                }
                return aitem
            })
            return newState
        })
    }
    return (
        <div className={className}>
            <div className="item-list">
                <AccordionList lang={lang} __html={__html} reactIcon={reactIcon} accordionItems={accItems} handleClick={handleClick} />
            </div>
            {!withoutImage && <div className="item-image">
                <motion.div initial="visible" variants={variants} animate={controls}>
                    <img  src={itemImage || accItems[0].image} alt=""/>
                </motion.div>
            </div>}
        </div>
    );
})`
  display: flex;
  height:${props => props.height ? props.height : "400px"};
  justify-content: space-evenly;
  align-items: center;
  flex-wrap:wrap;
  >div {
    width:100%;
    @media screen and (min-width: 700px) {
      width:50%;
    }
  }
  .item-image {
    >div {
      width:100%;
    }
    display: none;
    @media screen and (min-width: ${media_queries.mobile}px){
      display: flex;
      max-width:350px;
    } 
  }
  img {
    display: block;
    max-width:480px;
    width:100%;
    margin:auto;
  }
`

export default ImageAccordion;
