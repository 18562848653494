import React, {useEffect} from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { IoChevronForward } from "react-icons/io5"
import {colors} from "../../const/colors";


export const LinkForward = styled(({className,link, text}) => {

    return (
        <Link className={className} to={link}>{text} <IoChevronForward color={colors.greenColor} /></Link>
    )
})`
  text-decoration:none;
  color: ${colors.greenColor};
  display:flex;
  align-items: center;
  font-weight:bold;
  svg {
    transform: translateX(0);
    transition:all .4s ease;
  }
  &:hover {
    cursor: pointer;
    svg {
      position:relative;
      transform:translateX(5px);
    }
  }
`