import React from "react"
import styled from "styled-components"
import {colors} from "../../const/colors";
import {LinkForward} from "../LinkForward/styles";
import  AnimatedInViewWrapper  from "../AnimatedInViewWrapper"
import {media_queries} from "../../const/media_queries";
import Img from "gatsby-image"
export const Section = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 50px 0;

  //> div {
  //  width: 100%;
  //  margin-bottom: 50px;
  //  text-align: center;
  //  @media screen and (min-width: 768px) {
  //    width: 50%;
  //    text-align: left;
  //  }
  //}

  @media screen and (min-width: 768px) {
    padding: 75px 0;
  }
`


export const HeroSection = styled(Section)`
  p {
    font-size: 1.2rem;
  }
  height:calc(100vh - 89px);
  > div {
    //margin:auto;
    > h2 {
      text-align: center;
      @media screen and (min-width:992px) {
        text-align: left;
      }
    }
  }
  p {
    text-align: center;
    @media screen and (min-width: 992px) {
      text-align: left;
    }
  }
  > div:last-child {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom:0;
    display:none;
    position:relative;
    img {
      display:block;
      left:0;
      top:0;
      margin:auto;
      &:first-child {
        max-width:470px;
        width:100%;
      }
      &:last-child {
        position: absolute;
        //max-width:380px;
        left:50%;
        top:50%;
        transform:translate(-50%, -50%);
        width:80.85%;
      }
    }
    @media screen and (min-width: 992px) {
      width: 50%;
      display:block;
    }
  }
`

export const AboutSection = styled(Section)`
  flex-wrap:wrap;
  > div:first-child {
    flex-grow:1;
    display:none;
    @media screen and (min-width: 992px) {
      display:flex;
    }
    img {
      display: block;
      max-width: 340px;
    }
  }
  > div:last-child {
    display:flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
    }
    @media screen and (min-width: 600px) {
      align-items: flex-start;
      h2 {
        text-align:left;
      }
    }
    p {
      text-align: center;
      width:80%;
      @media screen and (min-width: 600px) {
        width:100%;
        text-align: left;
      }
    }
  }
`
export const ServiceSection = styled.section`
  padding: 50px 0;
  background: #f7f7f7;
  @media screen and (min-width: 768px) {
    padding: 75px 0;
  }
`
export const ServiceBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap:wrap;
  margin: 50px auto;
`
export const ServiceBox = styled(({className, img, title, subtitle, children}) => {
    return (
        <div className={className}>
            <div>
                <img src={img} alt="title"/>
            </div>
            <span className="title">{title}</span>
            <span className="subtitle">{subtitle}</span>
            { children && <div className="backend">
                {children}
            </div>}
        </div>
    )
})`
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  background: white;
  padding: 20px;
  min-height: 340px;
  border-top: 5px solid ${colors.greenColor};
  border-radius: 3px;
  margin-bottom:20px;
  text-align: center;
  @media screen and (min-width: 768px) {
    width: 49%;
  }
  @media screen and (min-width: 1170px) {
    width: 32%;
  }

  > div:first-child {
    margin-bottom: 30px;

    img {
      max-width: ${props => props.iconWidth ? props.iconWidth : "93px"};
      margin: auto;
    }
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    display: inline-block;
    color: ${colors.grayColor}
  }
  .subtitle {
    font-size:1rem;
  }
  .backend {
    position:absolute;
    padding:20px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    bottom:0;
    top:0;
    left:0;
    width:100%;
    background:${colors.greenColor};
    color:white;
    transform:scaleY(0);
    transform-origin: center top;
    opacity:0;
    transition:all .3s ease;
  }
  &:hover {
    .backend {
      transform:scaleY(1);
      opacity:1;
    }
  }
`
export const CallToAction = styled.div`
  display:flex;
  flex-direction: column;
  text-align: center;
  padding:50px 0;
  background: ${colors.greenColor};
  color:white;
  h2 {
    font-size: clamp(1.7rem, 6vw, 2.3rem);
    text-transform: uppercase;
  }
  button {
    //width:200px;
    width:100px;
    max-width:300px;
    //padding:15px;
    margin:auto;
    font-weight: bold;
  }
`
export const BerrySection = styled(Section)`
  ul {
    list-style-type:none;
    display: flex;
    width:100%;
    flex-wrap:wrap;
    li {
      display:flex;
      justify-content: center;
      @media screen and (min-width: 768px) {
        justify-content: flex-start;
      }
      align-items: center;
      width:50%;
    }
  }
  >div:first-child {
    width:100%;
    max-width:900px;
  }
  //>div:last-child {
  //  p {
  //    text-align: left;
  //  }
  //}
  svg {
    margin-right:10px;
    color: ${colors.greenColor}
  }
  .berry-content {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    width:100%;
    >div:first-child {
      //display:none;
      //@media screen and (min-width: 768px){
      //  display: flex;
      //}
      max-width:500px;
      width:100%;
      margin:auto;
      img {
       display:block;
      }
      @media screen and (min-width: 768px) {
        width:30%;
      }
      img {
        max-width:300px;
        margin:auto;
      }
    }
    >div:last-child {
      width:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      @media screen and (min-width: 600px){
        align-items: flex-start;
      }
      @media screen and (min-width: 768px){
        width:60%
      }
      ul {
        margin-left:0;
        
      }
      p {
        text-align: center;
        width:80%;
        @media screen and (min-width: 600px) {
          width:100%;
          text-align: left;
        }
      }
    }
  }
 `

export const PortfolioSection = styled.section`
  display:flex;
  width:100%;
  background:${colors.lightBg};
  padding:50px 0;
  @media screen and (min-width: 768px) {
    padding:75px 0;
  }
`

export const PortfolioBoxWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap:wrap;
`
export const PortfolioBox = styled(({className, title, subtitle, icon, link, linkText, step, video}) => {
    return (
        <div className={className}>
            <div>
                { video ? <video autoPlay loop>
                    <source src={video} />
                </video> : <Img style={{width:"100%"}} fluid={icon} />}
            </div>
            <span className="title">{title}</span>
            <span className="subtitle">{subtitle}</span>
            <AnimatedInViewWrapper
                initial="hidden"
                variants={{
                    visible: {
                        y:0,
                        opacity:1,
                        transition: {
                            duration:.6,
                            delay: step * 0.3
                        }
                    },
                    hidden: {
                        y:"20px",
                        opacity:0,
                        transition: {
                            duration:.6
                        }
                    }
                }}>
                <LinkForward link={link} text={linkText} />
            </AnimatedInViewWrapper>
            {/*<div>*/}
            {/*    <LinkForward to={link} text={linkText} />*/}
            {/*</div>*/}
        </div>
    )
})`
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    min-height:485px;
    width:100%;
    background:white;
    margin-top:30px;
    padding-bottom: 30px;
    border-radius:5px;
    @media screen and (min-width: 768px) {
      width: 49%;
    }
    @media screen and (min-width:${media_queries.mobile}px) {
      width: 32%;
    }
    >div:first-child {
      display:flex;
      width:100%;
      height:100%;
      //height:250px;
      //background:${colors.greenColor};
      margin-bottom:35px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    img {
      display: block;
      width:100%;
      height:100%;
      margin:0 auto;
      //max-width:75px;
      //margin:auto;
    }
    video {
      display: block;
      width:100%;
    }
    .title {
      display: inline-block;
      font-size:1.1rem;
      color: ${colors.grayColor};
      font-weight:bold;
      margin-bottom:15px;
    }
    .subtitle {
      width:65%;
      text-align: center;
      margin-bottom:25px;
    }
`
export const TestimonialSection = styled.section`
  display:flex;
  width:100%;
  background:${colors.lightBg};
  padding:50px 0;
  @media screen and (min-width: 768px) {
    padding:75px 0;
  }
  .carousel-root {
    width:100%;
  }
`
