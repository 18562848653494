import styled from "styled-components"
import { colors } from "../../const/colors"
import { motion, useAnimation } from "framer-motion"
import React, {useEffect, useContext} from 'react';
import { GlobalContext} from "../GlobalProvider";
import {useCheckWindowSize} from "../../hooks/checkWindowSize";
import {media_queries} from "../../const/media_queries";

function Title({className, children, animate, custom, forChange} ) {
    const controls = useAnimation()
    const { window_width } = useContext(GlobalContext)
    let cWidth = 0
    useEffect(() => {
        if(window_width === 0) {
            cWidth = window.innerWidth
        }else {
            cWidth = window_width
        }
        if( forChange) {
            if (cWidth < 547) {
                // animate = {...animate, y: ["100px", "15px", "15px", "-100px", "100px", "100px"]}
                animate = {...animate, y: ["-100px", "15px", "15px", "100px", "-100px", "-100px"]}
            } else if (cWidth > 547 && cWidth < 768 ) {
                // animate = {...animate, y: ["100px", "30px", "30px", "-100px", "100px", "100px"]}
                animate = {...animate, y: ["-100px", "30px", "30px", "100px", "-100px", "-100px"]}
            } else if (cWidth > 768 && cWidth < 1015) {
                // animate = {...animate, y: ["100px", "15px", "15px", "-100px", "100px", "100px"]}
                animate = {...animate, y: ["-100px", "15px", "15px", "100px", "-100px", "-100px"]}
            } else {
                // animate = {...animate, y: ["100px", "0px", "0px", "-100px", "100px", "100px"]}
                animate = {...animate, y: ["-100px", "0px", "0px", "100px", "-100px", "-100px"]}
            }
        }

        controls.start(i => ({
            ...animate,
            transition: {
                ...animate.transition,
                onComplete: v => {
                   if(i) {
                       animate.transition.delay = 6.5
                       animate.transition.repeat = Infinity
                   }
                }
            }
        }) )
    },[animate,window_width])
    return (
        <motion.h2 custom={custom} className={className} animate={controls}>
            {children}
        </motion.h2>
    );
}

export const SectionTitle = styled.h2`
  text-transform:uppercase;
  font-size: clamp(1.5rem, 6vw, 2.3rem);
  line-height:2.5rem;
  @media screen and (min-width: ${media_queries.mobile}px) {
    line-height:60px;
  }
  letter-spacing: 1px;
  color: ${props => props.color ? props.color : colors.grayColor};
  text-align: ${props => props.align ? props.align : "left"};
`
export const AnimatedSectionTitle = styled(Title)`
  text-transform: uppercase;
  font-size: clamp(1.5rem, 6vw, 2.3rem);
  line-height: 2.5rem;
  @media screen and (min-width: ${media_queries.mobile}px) {
    line-height: 60px;
  }
  letter-spacing: 1px;
  color: ${colors.grayColor};
`

export const HeroSectionTitle = styled(SectionTitle) `
  font-size: clamp(2.5rem, 6vw, 3.5rem);
  line-height: 2.7rem;
  text-align: center;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
  @media screen and (min-width: ${media_queries.mobile}px) {
    line-height:70px;
  }
  letter-spacing: 2px;
`
export const AnimatedHeroSectionTitle = styled(AnimatedSectionTitle) `
  font-size: clamp(2.5rem, 6vw, 3.5rem);
  line-height: 2.7rem;
  text-align: center;
  opacity: ${props => props.opacity ? props.opacity : "1"};
  @media screen and (min-width: 992px) {
    text-align: left;
  }
  @media screen and (min-width: ${media_queries.mobile}px) {
    line-height:70px;
  }
  letter-spacing: 2px;
`