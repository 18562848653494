import {colors} from "./colors";

export const BannerStyles = {
    h2: {
        textTransform: "uppercase",
        color:`${colors.greenColor}`,
        fontSize: "clamp(1.9rem,6vw,3rem)",
        fontWeight: "bold",
        letterSpacing: "2px"
    },
    span: {
        color: `${colors.grayColor}`,
        fontSize: "clamp(1rem,6vw,1.4rem)",
        textTransform: "Initial",
        display:"inline-block",
        letterSpacing: ".8px"
    }
}