import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container} from "../components/Container";
import { BannerSection, BenefitsSection, ModernWaySection, PracticesSection, AddPackagesSection } from "../components/WebsitePage/styles";
import { SectionTitle} from "../components/SectionTitle/styles";
import { colors } from "../const/colors"
import {IoCheckmark} from "react-icons/io5";
import Speed from "../images/speedWebsite1.svg"
import Optimization from "../images/optimizationWebsite1.svg"
import Security from "../images/securityWebsite1.svg"
import {InfoBox} from "../components/Footer/styles";
import {ButtonGf, PopupText, ButtonLink} from "../components/Button/styles";
import CircleTwo from "../images/greyCirclesLeft.svg"
import CircleThree from "../images/grayCirclesRight.svg"
import DevelopmentScreen from "../images/developmentWebsite.svg"
import ImageAccordion  from "../components/ImageAccordion"
import AddToWebsite from "../components/AddToWebsite";
import {BerrySection} from "../components/HomePage/styles";
import AnimatedInViewWrapper from "../components/AnimatedInViewWrapper";
import {LinkForward} from "../components/LinkForward/styles";
import GreenBerry from "../images/berrySection.svg"
import CirclesFour from "../images/circlesfour.svg";
import { BannerStyles} from "../const/inline_styles";
import PerformanceSmall from "../images/performanceSmall.svg";
import PerformanceBig from "../images/performance.svg";
import SecuritySmall from "../images/securityWebsite1.svg";
import SecurityBig from "../images/security.svg";
import ScalabilitySmall from "../images/scalabilitySmall.svg";
import ScalabilityBig from "../images/scalability.svg";

import localData from "../localization/website.json"

// const flags = {
//     "en": localData.locales[0].value,
//     "es": localData.locales[1].value
// }

const flags = [
    {
        name: "en",
        uri: localData.locales[0].value
    },
    {
        name: "es",
        uri : localData.locales[1].value
    }
]

const accordionItems = [
    {
        id:1,
        title: {
            en: "Performance",
            es: "Rendimiento"
        },
        content: {
            en: "With our tech stack, no manual tuning is required. We let it handle lazy-loading, media optimization, prefetching resources, and more. This in turn makes your website a performance beast.",
            es: "Con nuestra pila de tecnología, no se requiere ningún ajuste manual. Dejamos que maneje la carga diferida, la optimización de medios, la obtención previa de recursos y más. Esto, a su vez, convierte a tu sitio web en una bestia de rendimiento."
        },
        icon: {
            name: PerformanceSmall
        },
        image: PerformanceBig,
        open: true,
    },
    {
        id:2,
        title: {
            en: "Security",
            es: "Seguridad"
        },
        content: {
            en: "We generate HTML at run time. When there is no need for a server there cannot be any malicious requests involved. The possibility of an attack is non-existent.",
            es: "Generamos HTML en tiempo de ejecución. Cuando no hay necesidad de un servidor, no puede haber solicitudes maliciosas involucradas. La posibilidad de un ataque no existe."
        },
        icon: {
            name: SecuritySmall,
            size: "24px"
        },
        image: SecurityBig,
        open: false,
    },
    {
        id:3,
        title: {
            en: "Scalability",
            es: "Escalabilidad"
        },
        content: {
            en: "The website does not require any complex framework or a specific expensive host to operate on but yet can be scaled to any kind of web application if needed.",
            es: "El sitio web no requiere ningún marco complejo tampoco un host costoso específico para operar, pero aún se pueda escalar a cualquier tipo de aplicación web si es necesario."
        },
        icon: {
            name: ScalabilitySmall,
            size: "24px"
        },
        image: ScalabilityBig,
        open: false,
    }
]


const IndexPage = ({data, location, pageContext}) => {

  const lang = pageContext.lang

    const meta = data?.template?.locales.find(l => l.iso === lang)
    const metaStatic = localData.locales.find(l => l.iso === lang)

  return <Layout location={location} lang={lang} flags={flags}>
    <SEO
        title={ meta?.metaTitle || metaStatic.metaTitle}
        description={ meta?.metaDescription || metaStatic.metaDescription}
        keywords={ meta?.metaKeywords || metaStatic.metaKeywords}
    />
    <BannerSection>
        <Container>
            <h2 dangerouslySetInnerHTML={{__html: localData.pageTitle[lang]}} style={BannerStyles.h2} />
            <SectionTitle>
                {localData.sections.hero.title[lang]}
            </SectionTitle>
            <p style={{maxWidth:"900px"}}>{localData.sections.hero.description[lang]}</p>
            <div className="info-box-list">
                <InfoBox step={1} iconImage={Speed} text={localData.sections.hero.group.first[lang]} color={colors.grayColor} />
                <InfoBox step={2} iconImage={Optimization} text={localData.sections.hero.group.second[lang]} color={colors.grayColor} />
                <InfoBox step={3} iconImage={Security} text={localData.sections.hero.group.third[lang]} size="30px" color={colors.grayColor} />
            </div>
            <div className="free-consultation">
                <PopupText text={localData.sections.hero.button.label[lang]} url="https://calendly.com/tina-greenflamingo/30min" />
            </div>
        </Container>
        <div className="bg-image">
            <img src={CirclesFour} alt="circles"/>
        </div>
    </BannerSection>
      <BenefitsSection>
          <Container>
              <SectionTitle align="center">{localData.sections.benefits.title[lang]}</SectionTitle>
              <div className="content">
                <p style={{textAlign:"center"}}>{localData.sections.benefits.description[lang]}</p>

                  <ul>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.first[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.second[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.third[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fourth[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.fifth[lang]}} /></li>
                      <li><IoCheckmark color={colors.greenColor} /><div dangerouslySetInnerHTML={{__html: localData.sections.benefits.group.sixth[lang]}} /></li>
                  </ul>
              <div className="get-in-touch">
                  <h4>{localData.sections.benefits.sectionSubTitle[lang]}</h4>
                  <span>{localData.sections.benefits.sectionSubDescription[lang]}</span>
                  {/*<ButtonGf>Start</ButtonGf>*/}
                  <div className="free-consultation free-consultation--flex">
                      <PopupText url="https://calendly.com/tina-greenflamingo/30min" text={localData.sections.benefits.button.label[lang]} />
                  </div>
              </div>
              </div>
          </Container>
          <img className="left" src={CircleTwo} alt="circles two"/>
          <img className="right" src={CircleThree} alt="circles three"/>
      </BenefitsSection>
      <ModernWaySection>
          <Container>
              <SectionTitle align="center">{localData.sections.develop.title[lang]}</SectionTitle>
              <div className="content">
                  <p>{localData.sections.develop.description[lang]}</p>
                  <ul>
                      <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.develop.group.first[lang]}</span></li>
                      <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.develop.group.second[lang]}</span></li>
                      <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.develop.group.third[lang]}</span></li>
                      <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.develop.group.fourth[lang]}</span></li>
                      <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.develop.group.fifth[lang]}</span></li>
                      <li><IoCheckmark color={colors.greenColor} /><span>{localData.sections.develop.group.sixth[lang]}</span></li>
                  </ul>
                  <div>
                      <img style={{maxWidth:"500px"}} src={DevelopmentScreen} alt=""/>
                  </div>
                  <div style={{display:"flex", justifyContent: "center"}}>
                      <ButtonLink to={localData.sections.develop.button.uri[lang]} background={colors.greenColor} color="white" bgHoverColor={colors.grayColor}>{localData.sections.develop.button.label[lang]}</ButtonLink>
                  </div>
              </div>
          </Container>
      </ModernWaySection>
      <PracticesSection>
          <Container>
              <SectionTitle align="center">{localData.sections.built.title[lang]}</SectionTitle>
              <ImageAccordion lang={lang} accordionItems={accordionItems} />
          </Container>
      </PracticesSection>
      <AddPackagesSection>
          <Container>
              <SectionTitle align="center">{localData.sections.addto.title[lang]}</SectionTitle>
              <p style={{textAlign: "center"}}>{localData.sections.addto.description[lang]}</p>
              <AddToWebsite lang={lang} data={localData.sections.addto} subject={localData.sections.addto.subject[lang]} />
          </Container>
      </AddPackagesSection>
      <BerrySection>
          <Container>
            <div className="berry-content">
                <div>
                    <img src={GreenBerry} alt="Green Berry"/>
                </div>
                <div>
                    <SectionTitle>{localData.sections.berry.title[lang]}</SectionTitle>
                    <p>{localData.sections.berry.description[lang]}</p>
                    <AnimatedInViewWrapper
                        initial="hidden"
                        variants={{
                            visible: {
                                y:0,
                                opacity:1,
                                transition: {
                                    duration:.6
                                }
                            },
                            hidden: {
                                y:"20px",
                                opacity:0,
                                transition: {
                                    duration:.6
                                }
                            }
                        }}>
                        <LinkForward link={localData.sections.berry.button.uri[lang]} text={localData.sections.berry.button.label[lang]} />
                    </AnimatedInViewWrapper>
                </div>
            </div>
          </Container>
      </BerrySection>
  </Layout>
}

export default IndexPage
