import React, {useState, useContext} from 'react';
import styled from "styled-components"
import Cross from "../../images/crosswithdot.svg"
import {SectionTitle} from "../SectionTitle/styles";
import { IoCheckmark } from "react-icons/io5";
import { colors } from "../../const/colors"
import {ButtonGf} from "../Button/styles";
import GlobalContext from "../../context/GlobalContext";
import { navigate } from "gatsby"

export const PackageBox = styled(({className, lang, content, icon, title, handleClick, id, choosed}) => {
    const Add = {
        en: "Add",
        es: "Agrega"
    }
    const Remove = {
        en: "Remove",
        es: "Eliminar"
    }
    return <div className={className} onClick={() => handleClick(id)}>
        <div><img src={icon} alt=""/></div>
        <span>{title}</span>
        <p>{content}</p>
        <ButtonGf>{choosed ? Remove[lang] : Add[lang]}</ButtonGf>
    </div>
})`
display: flex;
  width:100%;
  padding:20px;
  text-align: center;
  margin-bottom:20px;
  max-width:485px;
  &:hover {
    cursor:pointer;
  }
  >div {
    width:30px;
    img {
      width:100%;
    }
  }
  @media screen and (min-width: 768px) {
    width:48%;
  }
  @media screen and (min-width: 1147px) {
    width:32%;
  }
  flex-direction: column;
  align-items: center;
  background:white;
  border: ${props => props.choosed ? `2px solid ${colors.greenColor}` : null};
  max-height:300px;
  height:100%;
  span {
    color: ${colors.grayColor};
    font-weight:bold;
    display:inline-block;
    margin-bottom:10px;
  }
  p {
    text-align: center;
    font-size:.95rem;
    padding:0 10%;
  }
`

const AddToWebsite = styled(({className, subject, lang, data}) => {

    const gContext = useContext(GlobalContext)
    const [additional, setAdditional] = useState([])
    const [selected, setSelected] = useState([
        {
            id:1,
            title: {
                en: "Branding",
                es: "Branding"
            },
            select: false,
        },
        {
            id:2,
            title: {
                en: "Social Media Management",
                es: "Gestión de redes sociales"
            },
            select: false,
        },
        {
            id:3,
            title: {
                en: "Content Marketing",
                es: "Marketing de contenidos"
            },
            select: false,
        },
    ])
    const handleQuoteClick = () => {
        if(additional.length > 0 ) {
            const additionalPackages = additional.map(addit => {
                return addit.name
            })
            gContext.setAdditionalPackages(additionalPackages)
        }
        navigate(`${data.buttonQuote.uri[lang]}?sub=${subject}`)
        // navigate("/contact")
    }
    const handleClick = (id) => {
        setSelected(prevState => {
            const newState = prevState.map((item) => {
                if(item.id === id){
                    item.select = !item.select
                    if(item.select){
                        setAdditional(prev => {
                            if(prev.length === 0 ){
                                return [{id: item.id, name: item.title[lang]}]
                            }else {
                                return [...prev, {id: item.id, name: item.title[lang]} ]
                            }
                        })
                    }else {
                        setAdditional(prev => {
                            return prev.filter((ob) => {
                                return ob.id !== id
                            })
                        })
                    }
                }
                return item
            })
           return newState
        })
    }

    return (
        <div className={className}>
            <div className="package-list">
                <PackageBox lang={lang} id={1} handleClick={handleClick} icon={Cross} title={data.group.first.title[lang]} content={data.group.first.description[lang]} choosed={selected[0].select} />
                <PackageBox lang={lang} id={2} handleClick={handleClick} icon={Cross} title={data.group.second.title[lang]} content={data.group.second.description[lang]} choosed={selected[1].select} />
                <PackageBox lang={lang} id={3} handleClick={handleClick} icon={Cross} title={data.group.third.title[lang]} content={data.group.third.description[lang]} choosed={selected[2].select} />
                {/*<PackageBox id={3} handleClick={handleClick} icon={Cross} title="Branding" content="Develop memorable company identity" choosed={selected[2].select} />*/}
            </div>
            <div className="your-package">
                <SectionTitle align="center">{data.subTitle[lang]}</SectionTitle>
                <div className="additional">
                <span><IoCheckmark color={colors.greenColor}/>{data.chosenPacks.first.title[lang]}</span>
                {additional.length > 0 &&
                        additional.map((ad) => {
                            return <span key={ad.id}><IoCheckmark color={colors.greenColor} />{ad.name}</span>
                        })
                }
                </div>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                marginTop:"50px"
            }}>
                <ButtonGf onClick={handleQuoteClick} color="white" background={colors.greenColor} bgHoverColor={colors.grayColor}>{data.buttonQuote.label[lang]}</ButtonGf>
            </div>
        </div>
    );
})`
  margin-top:50px;
  .package-list {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    @media screen and (min-width: 768px) {
      justify-content: space-between;
    }
    align-items: center;
    margin-bottom:50px;
    //height:200px;
  }
  .your-package {
      display:flex;
      flex-direction: column;
      align-items: center;
      background:white;
      padding:20px;
    >span {
      display:flex;
      align-items: center;
      svg {
        margin-right:5px;
      }
    }
    }
  .additional {
    text-align: center;
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
    strong {
      display: block;
      color: ${colors.grayColor};
      margin-top:20px;
    }
    span {
      display:flex;
      align-items: center;
      margin-right:10px;
      svg {
        margin-right:5px;
      }
    }
    >div {
      display:flex;
      justify-content:space-between;
      margin-top:20px;
      flex-wrap:wrap;
      span {
        display:flex;
        align-items: center;
        margin-right:10px;
        svg {
          margin-right:5px;
        }
      }
    }
  }
`

export default AddToWebsite;
