import styled from "styled-components"
import { Container } from "../Container"
import { colors } from "../../const/colors";

export const SectionWrapper = styled.section`
  position: relative;
  padding:108px 0 108px 0;
  .img-block {
    margin-bottom:30px;
  }
`

export const BannerSection = styled.section`
  position:relative;
  display:flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 89px);
  .free-consultation {
    display: flex;
    margin:30px 0;
  }
  .info-box-list {
    display:flex; 
    flex-wrap:wrap;
    max-width:700px;
    justify-content: space-between;
    font-weight:bold;
  }
  .berry-cms-banner-section {
    display:flex;
    justify-content: space-between;
    align-items: center;
    > div:first-child {
      width:100%;
      @media screen and (min-width: 992px){
        width:65%;
      }
    }
    > div:last-child {
      display:none;
      @media screen and (min-width: 992px){
        display:block;
        width:35%;
      }
      img {
        display:block;
        width:100%;
        max-width:300px;
        margin:auto;
      }
    }
  }
  .bg-image {
    display:none;
    @media screen and (min-width: 1300px){
      display:block;
    }
    position: absolute;
    left:calc(100% - 250px);
    bottom:-100px;
    width:550px;
    img {
      display:block;
      width:100%;
    }
  }
`

export const BenefitsSection = styled(SectionWrapper)`
  //p {
  //  margin:15px auto;
  //}
  ${Container} {
    position:relative;
    z-index:100;
  }
  overflow: hidden;
  .content {
    margin:50px auto;
    max-width:900px;
  }
  ul {
    display:flex;
    flex-wrap:wrap;
    margin:70px auto;
    max-width:800px;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    li {
      width:100%;
      display: flex;
      margin-bottom:25px;
      align-items: center;
      justify-content: flex-start;
      svg {
        margin-right:10px;
      }
      @media screen and (min-width: 768px){
        width:49%;
      }
    }
  }
  .get-in-touch {
    h4 {
      color:${colors.grayColor}
    }
    text-align: center;
    span {
      display:block;
      margin-bottom:30px;
    }
    button {
      min-width:150px;
    }
  }
  img {
    display:block;
    position:absolute;
    &.left {
      display:none;
      @media screen and (min-width: 1400px){
        display:block;
        max-width:200px;
        width:100%;
        left:2%;
        bottom:100px;
      }
    }
    &.right {
      max-width:450px;
      width:100%;
      left:70%;
      bottom:-140px;
    }
  }
`
export const ModernWaySection = styled(SectionWrapper)`
  background: ${colors.lightBg};
  .content {
    max-width:1100px;
    margin:auto;
  }
  img {
    display: block;
    max-width:236px;
    margin:50px auto;
    width:100%;
  }
  p {
    text-align: center;
  }
  ul {
    margin-top:50px;
    list-style-type: none;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 768px) {
      margin-left:2rem;
    }
    @media screen and (min-width: 900px) {
      margin-left:4rem;
    }
    @media screen and (min-width: 1147px){
      margin-left:8rem;
    }
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom:25px;
    width:100%;
    svg {
      margin-right:10px;
    }
    @media screen and (min-width:600px) {
      width:49%;
    }
    @media screen and (min-width:768px) {
      width:33%;
    }
  }
`
export const PracticesSection = styled(SectionWrapper)`
  
`
export const AddPackagesSection = styled(SectionWrapper)`
  background:${colors.lightBg}
`
